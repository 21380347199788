.nav-bar-home {
  border-bottom: 1px solid #70707021;
  color: #585858;
  font-size: 18px;
  display: flex;
  min-height: 60px;
  align-items: center;
  background-color: #fdfdfd;
  justify-content: space-between;
}

.nav-logo-div {
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 18px;
  color: #585858;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.nav-icon {
  display: flex;
  align-items: center;
}

.header-nav-link-div {
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.header-nav-link {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 10px;
}
